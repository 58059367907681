<template>
  <section class="profile">
    <div class="page-header">
      <h3 class="page-title">
        Catégorie
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Sample Pages</a></li>
          <li class="breadcrumb-item active" aria-current="page">Profile</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4">
                <div class="border-bottom text-center pb-4">
                  <picture v-if="this.pictures.length<1">
                    <source srcset="@/assets/images/import/addPicture.webp"  type="image/webp">
                    <source srcset="@/assets/images/import/addPicture.png" type="image/png">
                    <img src="image" alt="il n'y pas d'image, Importer une image" class="mw-100 mb-3" v-b-modal.pictureUpdate/>
                  </picture>
                  <picture v-else>
                    <source :srcset="lastImage.content.webpImage"  type="image/webp">
                    <source :srcset="lastImage.content.originalImage" type="image/jpeg">
                    <img src="image" :alt="lastImage.content.alt" class="mw-100 mb-3"/>
                  </picture>

                  <!-- carousel -->
                  <b-carousel id="carousel1" controls indicators background="#ababab" :interval="4000" v-model="slide1">
                    <b-carousel-slide>
                      <img slot="img" src="../../assets/images/carousel/banner_1.jpg" alt="slide image">
                      <button type="button" class="btn btn-icon btn-youtube"><i class="mdi mdi-delete"></i></button>
                    </b-carousel-slide>
                    <b-carousel-slide>
                      <img slot="img" src="../../assets/images/carousel/banner_1.jpg" alt="slide image">
                      <button type="button" class="btn btn-icon btn-youtube"><i class="mdi mdi-delete"></i></button>
                    </b-carousel-slide>
                  </b-carousel>
                  
                  <p>{{category.description}}</p>
                  <div class="d-block d-sm-flex justify-content-center">
                    <b-button variant="gradient-success" v-b-modal.pictureUpdate>Ajouter une photo</b-button>
                  </div>
                  <!-- <h2 v-if="lastImage">{{lastImage.url}}</h2> -->
                </div>

                <!-- updatePicture -->
                <b-modal id="pictureUpdate" title="Ajouter une image" size="md">

                  <form action="" @submit.prevent="submitPicture">
                    <!-- importation image -->
                    <b-form-group label="Importer l'image" label-for="picture">
                      <b-form-file v-model="file" id="inpu8" :state="Boolean(file)" @change="onFileSelected" placeholder="Choisir ou glisser l'image ici"></b-form-file>
                    </b-form-group>
                    <p>selected File: {{file ? file.name : ''}}</p>

                    <!-- description de l'image -->
                    <b-form-group label="Description"  label-for="pictureDescription">
                      <b-form-textarea type="text" v-model="alt" id="alt" placeholder="Entrer la description"></b-form-textarea>
                    </b-form-group>

                    <button type="input">submit</button>
                  </form>
                </b-modal>

                <div class="border-bottom py-4">
                  <p>Meilleurs produits vendus</p>
                  <div>
                    <label class="badge badge-outline-dark">Chalk</label>
                    <label class="badge badge-outline-dark">Hand lettering</label>
                    <label class="badge badge-outline-dark">Information Design</label>
                    <label class="badge badge-outline-dark">Graphic Design</label>
                    <label class="badge badge-outline-dark">Web Design</label>
                  </div>
                </div>
                <!-- <div class="py-4">
                  <p class="clearfix">
                    <span class="float-left">
                      Status
                    </span>
                    <span class="float-right text-muted">
                      Active
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      Phone
                    </span>
                    <span class="float-right text-muted">
                      006 3435 22
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      Mail
                    </span>
                    <span class="float-right text-muted">
                      Jacod@testmail.com
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      Facebook
                    </span>
                    <span class="float-right text-muted">
                      <a href="javascript:void(0);">David Grey</a>
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      Twitter
                    </span>
                    <span class="float-right text-muted">
                      <a href="javascript:void(0);">@davidgrey</a>
                    </span>
                  </p>
                </div> -->
                <!-- <b-button variant="gradient-primary btn-block">Preview</b-button> -->
              </div>
              <div class="col-lg-8">
                <div class="d-lg-flex justify-content-between">
                  <div>
                    <h3 class="mt-3 mt-lg-0">{{category.category}}</h3>
                    <div class="d-flex align-items-center">
                    </div>
                  </div>
                  <div class="mt-3 mt-lg-0">
                    <b-button variant="outline-secondary btn-icon">
                      <i class="mdi mdi-comment-processing"></i>
                    </b-button>
                    <b-button variant="gradient-primary" v-b-modal.editCategory>Modifier la catégorie</b-button>
                  </div>
                </div>

                <!-- modifier la catégorie -->
                <b-modal id="editCategory">
                  <form action="" @submit.prevent="submitCategory">
                    <!-- importation image -->
                    <b-form-group label="Importer l'image" label-for="picture">
                      <b-input v-model="categoryName"  placeholder="Modifier le nom de la catégorie"></b-input>
                    </b-form-group>

                    <!-- description de l'image -->
                    <b-form-group label="Description"  label-for="categoryDescription">
                      <b-form-textarea type="text" v-model="categoryDescription" id="alt" placeholder="Entrer la description de la catégorie"></b-form-textarea>
                    </b-form-group>

                    <button type="input">submit</button>
                  </form>
                </b-modal>
                <div class="mt-4 py-2 border-top border-bottom">
                  <ul class="nav profile-navbar">
                    <li class="nav-item">
                      <a class="nav-link active" href="javascript:void(0);">
                        <i class="mdi mdi-comment"></i>
                        Listes des produits
                      </a>
                    </li>
                    <!-- <li class="nav-item">
                      <a class="nav-link" href="javascript:void(0);">
                        <i class="mdi mdi-calendar"></i>
                        Agenda
                      </a>
                    </li> -->
                    
                  </ul>
                </div>
                <div class="row">
                  <div class="col-md-6 grid-margin stretch-card" v-for="(product,z) in products" :key="z">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex flex-row flex-wrap">
                          <img src="@/assets/images/faces/face11.jpg" class="img-lg rounded" alt="profile image" />
                          <div class="ml-0 ml-xl-3 mt-2 mt-xl-0">
                            <h6>{{product.name}}</h6>
                            <p class="text-muted">{{product.datemodified}}</p>
                            <p class="mt-2 text-success font-weight-bold">{{product.price}} €</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const axios = require ('axios').default
export default {
    name: 'categoryInfo',
    data() {
      return {
          myinfos:'',
          userratings:[],
          category:'',
          ulid:'',
          alt:'',
          file: null,
          pictures:[],
          products: [],
          categoryName:'',
          categoryDescription:''
      }
  },
  computed: {
      lastImage() {
          return this.pictures.slice(-1)[0]
      }
  },
  components: {
      
  },
  
  methods: {
      getPicture() {
        axios.get(`https://sites.comolapapaya.com/imgs/entity/categories/${this.ulid}`, {
            headers: {
                'X-AUTH-TOKEN': localStorage.getItem('token')
            }
        })
        .then(resPicture=> {
            this.pictures= resPicture.data.result
            console.log('resultat image', this.pictures)
            })
        .catch(errPicture=> console.log(errPicture))
      },
      onFileSelected (event) {
          this.file=event.target.files[0]
        },
      submitPicture () {
          const fd= new FormData
          fd.append ('imgfile', this.file)
          fd.append ('alt', this.alt)
          fd.append ('ulidentity', this.ulid)

          axios.post('https://sites.comolapapaya.com/imgs', fd, 
          {
            headers: {
              'X-AUTH-TOKEN': localStorage.getItem('token'),
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(res=> console.log('apiresponse',res))
          .catch(err=> console.log(err))
      },
      getProduct() {
        axios.get(`https://sites.comolapapaya.com/products/categories/${this.ulid}`)
        .then(resProduct=> {
          this.products=resProduct.data.result
        })
        .catch(errProduct=> console.log({errProduct}))
      },
      submitCategory() {
        axios.put(`https://sites.comolapapaya.com/categories/${this.ulid}`,
        {
          category: this.categoryName,
          description: this.categoryDescription
        },
        {
          headers: {
            'X-AUTH-TOKEN': localStorage.getItem('token')
          }
        })
        .then(resCategory=>{
          console.log(resCategory)
          location.reload()})
        .catch(errCategory=> console.log({errCategory}))
      }
  },
  mounted () {
      
  },
  async created () {
      this.ulid=this.$route.params.ulidCategory

      const response= await axios.get(`https://sites.comolapapaya.com/categories/${this.ulid}`)
      this.category=response.data.result
      this.categoryName=response.data.result.category
      this.categoryDescription=response.data.result.description
      console.log('category info params', this.category)

      this.getPicture()
      this.getProduct()
  }
}
</script>